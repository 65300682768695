import React from 'react'
import Layout from '../layouts/layout';
import SEO from '../layouts/seo';
import { graphql } from 'gatsby'
import { connect } from 'react-redux';
import SequenceRibbons from '../sequence/sequence-ribbons';
import StageIndexHeader from './../stageIndex/stageindex-header';
import StageIndexContent from './../stageIndex/stageindex-content';
import StageIndexLastUpdated from './../stageIndex/stageindex-lastupdated';
import ReadingProgress from 'react-reading-progress-plus'
import Sidenav from './../global/sidenav';

interface props {
  data: any
  browser: string
  transitionStatus: string
  pageContext: any
}

interface state {
  tabletOrMobile: boolean
  renderNav: boolean
  progress: boolean
}

class Staticpage extends React.Component<props, state> {
  resizeEventListener: Function

  constructor(props) {
    super(props);
    this.state = {
      tabletOrMobile: false,
      renderNav: false,
      progress: false
    };
  }

  componentDidMount = () => {
    this.windowResizeEvent();
    setTimeout(() => this.startProgressBar(), 500);
  }

  startProgressBar = () => this.setState({progress: true});

  windowResizeEvent = () => {
    this.checkTabletOrMobile();
    let ticking = false;

    // Resize event with throttling
    window.addEventListener('resize', this.resizeEventListener = (e) => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          this.checkTabletOrMobile();
          ticking = false;
        });
        ticking = true;
      }
    }); 
  }

  checkTabletOrMobile = () => {
    if (window.innerWidth < 995) {
      this.setState({
        tabletOrMobile: true,
        renderNav: true
      })
    } else {
      this.setState({
        tabletOrMobile: false,
        renderNav: true
      })
    }
  }

  componentWillUnmount = () => {
    // @ts-ignore
    window.removeEventListener('resize', this.resizeEventListener);
  }

  render () {
    let data = this.props.data;
    let {transitionStatus} = this.props;
    let context = this.props.pageContext;
    let parentsArray = [
      {url:'learning-areas', title:'Learning areas'},
      {url: data.pageData.slug, title: data.pageData.title}
    ]

    return (
      <>
        {this.state.progress ?
          <ReadingProgress targetEl="#sequence-content"/>
        :''}
        <SEO
          title={data.pageData.title} 
          description={data.pageData.seoDescription} 
        />
        <Layout
          data={data}
          class={data.pageData.slug}
          pageTitle={'Stages'} 
          parents={parentsArray}
          transitionStatus={transitionStatus}
        >
          <SequenceRibbons
            data={data}
            browser={this.props.browser}
          />
          <div className={`sequence-layout middle-container`}>
            <div className="sequence-layout__left">
              {!this.state.tabletOrMobile && this.state.renderNav ? 
                <Sidenav
                  data={data}
                  mobile={false}
                  pageType={`stageindex`}
                />
              :''}
            </div>
            <div className="sequence-layout__right" id="sequence-content">
              <StageIndexHeader
                data={data}
              />
              {this.state.tabletOrMobile && this.state.renderNav ? 
                <Sidenav
                  data={data}
                  mobile={true}
                  pageType={`stageindex`}
                />
              :''}
              <StageIndexContent
                data={data}
                stage={context.stage}
                mobile={this.state.tabletOrMobile}
              />
              <StageIndexLastUpdated
                data={data}
                stage={context.stage}
              />
            </div>
          </div>
        </Layout>
      </>
    )
  }
}


export const query = graphql`
  query($slug: String!) {
    site: datoCmsSite {
      ...siteData
    }
    nav: datoCmsNavigation {
      ...navData
    }
    footer: datoCmsFooter {
      ...footerData
    }
    pageData: datoCmsSequence(slug: {eq: $slug}) {
      slug
      title
      seoDescription
      lastUpdatedText
      levels
      primaryLevel
      subjects
      searchTags
      overview
      assessment
      sequenceListingDotPoints
      whatIsThisSequenceAbout
      teachingStrategies
      priorKnowledge
      vocabulary
      meta {
        updatedAt
      }
      victorianCurriculumConnection {
        title
        learningProgressionsText
        levels {
          ... on DatoCmsLevel {
            levelTitle
            levelContent
          }
        }
      } 
      stages {
        stageTitle
        stageRelativeUrl
        suggestedLearningIntensions
        lastUpdatedText
        successCriteria
        meta {
          updatedAt
        }
        contentSections {
          sectionContent
          sectionTitle
        }
        stageCurriculumDescription {
          contentDescriptionsContent {
            ... on DatoCmsContentDescriptionsText {
              contentText
            }
            ... on DatoCmsContentDescriptionsTitle {
              contentTitle
            }
          }
        }
      }
      sequenceImage {
        alt
        url
        fluid(maxHeight: 500) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`

export default connect(
  state => ({ 
    browser: state.browser,
  })
)(Staticpage);