import React from 'react'
import Img from 'gatsby-image/withIEPolyfill';
// @ts-ignore
import SequenceHeaderBreak from '../../svgs/sequence-header-break.svg'
// @ts-ignore
import ArrowLeftDark from '../../svgs/arrow-left-dark-red.svg' 
import AnimatedLink from './../global/animatedLink';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  EmailIcon,
} from 'react-share';

interface props {
  data: any
}

interface state {
  url: string
  titleSite: string
}

export default class StageIndexHeader extends React.Component<props, state> {
  
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      titleSite: " | DET Learning Sequences"
    };

  }

  componentDidMount = () => {
    this.setState({url: window.location.href})
  }

  render () {
    let data = this.props.data;
    let pageData = data.pageData;

    return (
      <div className={`sequence-header`}>
        <div className="sequence-header__left">
          <p className="sequence-header__subject stageIndex">{pageData.title}</p>
          <h1 className="sequence-header__title stageIndex">Stages</h1>
        </div>
        <div className="sequence-header__right">
          <Img fluid={pageData.sequenceImage.fluid} alt={pageData.sequenceImage.alt}/>
        </div>
        <div className="sequence-header__bottom stageIndex">
          <div className="sequence-header__bottom-left">
            <AnimatedLink to={`/${pageData.slug}`} className="sequence-header__stages-button left">
             <ArrowLeftDark/> Back to overview
            </AnimatedLink>
          </div>
        </div>
      </div>
    )
  }
}